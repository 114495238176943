<template>
    <div class='main'>网站建设中,敬请期待...</div>
    <div class='footer'>
        <a href="https://beian.miit.gov.cn/">鄂ICP备2022000891号-1（鄂ICP备2022000891号-2）</a>
    </div>
</template>
<script>
export default {
    name: 'page-index'
}
</script>
<style>
.main{
    height: calc(100% - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    font-weight: 600;
}
.footer{
    height: 100px;
    line-height: 100px;
}
</style>